var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0c6658deed5a11b5ac3a818c93a2d4806f468cd9"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://e00f037e168c971f20d88f13a20f85e0@o4505896418344960.ingest.us.sentry.io/4506955076009984",

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  autoSessionTracking: false, // Disables session cookies
  tracesSampleRate: 0, // Disables distributed tracing cookies

  enabled: process.env.NEXT_PUBLIC_ENVIRONMENT === "production",
});
